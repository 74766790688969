export enum EVENTS {
  appActionClicked = 'appActionClicked',
}

export enum APP_MANAGER_ACTIONS_EVENTS {
  openSettings = 'manageApp.chat.openSettings',
  openInbox = 'manageApp.chat.openInbox',
  scheduleHours = 'manageApp.chat.scheduleHours',
  setEmailNotifications = 'manageApp.chat.setEmailNotifications',
  createAutomations = 'manageApp.chat.createAutomations',
}

export const CHAT_APP_DEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2';
export const token = '';
