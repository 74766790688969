import { EditorSDK } from '@wix/platform-editor-sdk';
import { CHAT_APP_DEF_ID, token } from '../constants';

export const getChatComponentRef = async (editorSDK: EditorSDK) => {
  const appData = await editorSDK.tpa.app.getDataByAppDefId(
    token,
    CHAT_APP_DEF_ID,
  );
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId(
    token,
    appData.applicationId,
  );
  const component = components[0];
  return editorSDK.components.getById(CHAT_APP_DEF_ID, {
    id: component.id,
  });
};
