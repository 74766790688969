import { EditorSDK } from '@wix/platform-editor-sdk';
import { AppManifestBuilder } from '@wix/app-manifest-builder';
import { APP_MANAGER_ACTIONS_EVENTS, EVENTS, token } from '../constants';
import * as appActionEventHandler from './app-action-event-handler';
import { initTranslateFunction, t } from '../utils/i18n';
import { getBaseUrl } from '../utils/baseUrl';

let editorSDK: EditorSDK;

export const editorReady = async (
  _editorSDK: EditorSDK,
  _token,
  { initialAppData },
) => {
  editorSDK = _editorSDK;
  await editorSDK.addEventListener(EVENTS.appActionClicked, (event) =>
    appActionEventHandler.handleEvent(event, editorSDK),
  );
};

export const getAppManifest = async (
  { appManifestBuilder }: { appManifestBuilder: AppManifestBuilder },
  _editorSDK: EditorSDK,
) => {
  const [baseUrl, language] = await Promise.all([
    getBaseUrl(_editorSDK),
    _editorSDK.editor.environment.getLocale(),
  ]);
  await initTranslateFunction(language, baseUrl);
  return {
    appDescriptor: {
      mainActions: [
        {
          title: t('manageApp.openSettings'),
          actionId: APP_MANAGER_ACTIONS_EVENTS.openSettings,
          icon: 'appManager_settingsAction',
          type: 'settings',
        },
        {
          title: t('manageApp.openInbox'),
          actionId: APP_MANAGER_ACTIONS_EVENTS.openInbox,
          icon: 'appManager_pagesAction',
          type: 'settings',
        },
      ],
      customActions: [
        {
          title: t('manageApp.scheduleHours'),
          actionId: APP_MANAGER_ACTIONS_EVENTS.scheduleHours,
          icon: 'appManager_settingsAction',
          type: 'editorActions',
        },
        {
          title: t('manageApp.setEmailNotifications'),
          actionId: APP_MANAGER_ACTIONS_EVENTS.setEmailNotifications,
          icon: 'lineIcon_email',
          type: 'editorActions',
        },
        {
          title: t('manageApp.createAutomations'),
          actionId: APP_MANAGER_ACTIONS_EVENTS.createAutomations,
          icon: 'appManager_addElementsAction',
          type: 'editorActions',
        },
      ],
      defaultActions: {
        learnMoreKB: '2538fa4b-388f-4f21-ab62-0980f7b870ab',
      },
    },
  };
};

export const handleAction = async ({ type, payload }) => {
  if (type === 'migrate') {
    if (payload.type === 'delete-chat') {
      await editorSDK.tpa.app.delete(token);
    }
  }
};
