import {
  AppActionClicked,
  EditorSDK,
  IGetDataByAppDef,
} from '@wix/platform-editor-sdk';
import {
  APP_MANAGER_ACTIONS_EVENTS,
  CHAT_APP_DEF_ID,
  token,
} from '../constants';
import { getChatComponentRef } from './chat-editorsdk-helpers';

import { t } from '../utils/i18n';

export const handleEvent = async (
  event: CustomEvent<AppActionClicked['eventPayload']>,
  editorSdk: EditorSDK,
) => {
  switch (event.detail?.actionId) {
    case APP_MANAGER_ACTIONS_EVENTS.openSettings:
      await openChatSettings(editorSdk);
      break;
    case APP_MANAGER_ACTIONS_EVENTS.openInbox:
      await editorSdk.editor.openDashboardPanel(token, {
        url: '/inbox',
        closeOtherPanels: false,
      });
      break;
    case APP_MANAGER_ACTIONS_EVENTS.scheduleHours:
      await editorSdk.editor.openDashboardPanel(token, {
        url: '/inbox/settings/chat',
        closeOtherPanels: false,
      });
      break;
    case APP_MANAGER_ACTIONS_EVENTS.setEmailNotifications:
      await editorSdk.editor.openDashboardPanel(token, {
        url: '/inbox/settings/notifications',
        closeOtherPanels: false,
      });
      break;
    case APP_MANAGER_ACTIONS_EVENTS.createAutomations:
      await editorSdk.editor.openDashboardPanel(token, {
        url: '/inbox/settings/automations',
        closeOtherPanels: false,
      });
      break;
    default:
  }
};

const openChatSettings = async (editorSdk: EditorSDK) => {
  type AppData = IGetDataByAppDef & { settingsUrl: string };

  const [locale, currency, appData, componentRef] = await Promise.all([
    editorSdk.environment.getLocale(CHAT_APP_DEF_ID),
    editorSdk.info.getCurrency(CHAT_APP_DEF_ID),
    editorSdk.tpa.app.getDataByAppDefId(
      token,
      CHAT_APP_DEF_ID,
    ) as Promise<AppData>,
    getChatComponentRef(editorSdk),
  ]);

  await editorSdk.editor.openComponentPanel(CHAT_APP_DEF_ID, {
    url: `${appData.settingsUrl}?instance=${appData.instance}&appDefinitionId=${appData.appDefinitionId}&compId=tpaSettings&currency=${currency}&locale=${locale}&origCompId=${componentRef.id}&viewMode=editor`,
    type: editorSdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: 582,
    title: t('manageApp.widgetTitle'),
  });
};
