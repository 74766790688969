import Backend from 'i18next-http-backend';
import { default as i18next, TFunction } from 'i18next';

export let t: TFunction = (key: string) => '';

const i18nextCore = (locale, baseUrl, cb) =>
  i18next.use(Backend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}/assets/locale/messages_{{lng}}.json`,
        crossDomain: true,
      },
    },
    cb,
  );
const i18n = (
  locale,
  baseUrl,
): Promise<{ t: TFunction; i18nInstance: any }> => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(locale, baseUrl, (err, tFunc) =>
      err ? reject(err) : resolve({ t: tFunc, i18nInstance }),
    );
  });
};
export const initTranslateFunction = async (
  locale: string,
  baseUrl: string,
) => {
  const { t: tFunc } = await i18n(locale, baseUrl);
  t = tFunc;
};
